import axios from 'src/utils/axios';

export const GET_NOTIFICATIONS = '@notifications/get-notifications';

export function getNotifications() {
  const request = axios.get('/notifications');

  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_NOTIFICATIONS,
      payload: response.data
    }));
  };
}

export function updateNotifications(ids) {
  const request = axios.put('/notifications/mark-read', {notifications: ids});
  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_NOTIFICATIONS,
      payload: response.data
    }));
  };
}

export function deleteNotifications(ids) {
  const request = axios.put('/notifications/delete', {notifications: ids});
  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_NOTIFICATIONS,
      payload: response.data
    }));
  };
}
