// const SERVER_URL = 'https://qa.medtigo.com/';
// const SERVER_URL = 'http://localhost:8080/';
const SERVER_URL = 'https://dev.medtigo.com/';
const MARKET_URL = 'https://market.medtigo.com/';
const MEDTIGO_URL = 'https://medtigo.com/';
const WELCOME_URL = 'https://medtigo.com/welcome/';
const SIMULATION_URL = 'https://simulation.medtigo.com/';
const CONNECT_QA="https://dev.medtigo.com/"


const APP_URL = SERVER_URL + 'api/v1';

export {
  SERVER_URL,
  APP_URL,
  MARKET_URL,
  MEDTIGO_URL,
  WELCOME_URL,
  SIMULATION_URL,
  CONNECT_QA,
  
};
