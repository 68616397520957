import axios from 'axios';
import { APP_URL } from '../settings';

const instance = axios.create(
  {
    baseURL: APP_URL,
  }
);

export default instance;
