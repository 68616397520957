/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route, Link } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import LogoutGuard from 'src/components/LogoutGuard';
import GuestGuard from 'src/components/GuestGuard';

const routesConfig = [
  // {
  //   exact: true,
  //   path: '/',
  //   component: () => <Redirect to="/login" />
  // },
  {
    exact: true,
    path: '/',
    component: lazy(() => import('src/views/auth/UrlChecking'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    path: '/welcome',
    component: lazy(() => import('src/views/pages/welcome'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: LogoutGuard,
    path: '/logout',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot-password',
    component: lazy(() => import('src/views/auth/ForgotPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset-password',
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  // {
  //   exact: true,
  //   guard: GuestGuard,
  //   path: '/register',
  //   component: lazy(() => import('src/views/auth/RegisterNotAccept'))
  // },
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
