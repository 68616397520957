import React from 'react'
import jwtDecode from 'jwt-decode';
import jwt_decode from 'jwt-decode';

import axios from 'src/utils/axios';
import { APP_URL } from 'src/settings';
import {deDE} from "@material-ui/core/locale";
import {Router} from "react-router";

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);
          if (onLogout) {
            onLogout();
          }
        }
        return Promise.reject(error.response && error.response.data !== undefined ? error.response.data : error);
      }
    );
  };

  handleAuthentication() {
    // const accessToken = this.getAccessToken();

    // if (!accessToken) {
    //   return;
    // }

    // if (this.isValidToken(accessToken)) {
    //   this.setSession(accessToken);
    // } else {
    //   this.setSession(null);
    // }
  }

  defineTabID() {
    var iPageTabID = sessionStorage.getItem("tabID");
    // if it is the first time that this page is loaded
    if (iPageTabID == null) {
      var iLocalTabID = localStorage.getItem("tabID");
      // if tabID is not yet defined in localStorage it is initialized to 1
      // else tabId counter is increment by 1
      iPageTabID = (iLocalTabID == null) ? 1 : Number(iLocalTabID) + 1;
      // new computed value are saved in localStorage and in sessionStorage
      localStorage.setItem("tabID", iPageTabID);
      sessionStorage.setItem("tabID", iPageTabID);
    }
    return iPageTabID
  }

  handleRestrictToOneTab() {
    if (this.defineTabID() == localStorage.getItem("tabID")) {
      return false
    }
    return true
  }

  updateUserProfile = (user) => new Promise((resolve, reject) => {
    const formData = new FormData();
    for ( let key in user ) {
        formData.append(key, user[key]);
    }
    axios.put('/user/update', formData)
      .then((response) => {
        if (response.data) {
          const res = response.data;
          if(res?.updatedUser.profilePicture && res?.updatedUser.profilePicture.includes('files')){
            res.updatedUser.profilePicture = res.updatedUser.profilePicture + '?ms=' + new Date().getTime();
          }
          resolve(res);
        } else {
          reject(response.error);
        }
      })
      .catch((error) => {
        reject(error.error);
      });
  });

  loginWithEmailAndPassword = (email, password, from_courses) => new Promise((resolve, reject) => {
    axios.post('/auth/userLogin', { email, password, from_courses })
      .then((response) => {
        if (response.data) {
          this.setSession(response.data.token);
          resolve({user: response.data.data, coursesToken: response.data.coursesToken});
        } else {
          reject(response.message);
        }
      })
      .catch((error) => {
        reject(error.message);
      });
  });

  refreshToken = () => new Promise((resolve, reject) => {
    axios.post('/auth/refresh', {})
      .then((response) => {
        if (response.data) {
          this.setSession(response.data.token);
          resolve(response.data.data);
        } else {
          reject(response.message);
        }
      })
      .catch((error) => {
        reject(error.message);
      });
  });

  forgotPassword = (email) => new Promise((resolve, reject) => {
    axios.post('/user/forgot-password', { email })
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      })
      .catch((error) => {
        reject(error.error);
      });
  });

   syncOnWordpress = (data) => new Promise((resolve, reject) => {
    axios.post('/auth/wordpress-user-sync', data)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      })
      .catch((error) => {
        reject(error.error);
      });
  });

   sendWelcomeEmail = (data) => new Promise((resolve, reject) => {
    axios.post('/auth/welcome-email', data)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      })
      .catch((error) => {
        reject(error.error);
      });
  });

   addUserToRoot = (data) => new Promise((resolve, reject) => {
    axios.post('/auth/add-user-root', data)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      })
      .catch((error) => {
        reject(error.error);
      });
  });

  subscribeNewsletter = (userID, isAllNewsletters) => new Promise((resolve, reject) => {
    axios.get(`https://medtigo.com/wp-json/medtigo-user_newsletter_auth/v1/subscribe_all/?userid=${userID}&isAllNewsletter=${isAllNewsletters}`)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      })
      .catch((error) => {
        reject(error.error);
      });
  });

  changePassword = (values) => new Promise((resolve, reject) => {
    axios.put('/user/update/user/password', values)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      })
      .catch((error) => {
        reject(error.error);
      });
  });

  registerUser = (values, endpoint = '') => new Promise((resolve, reject) => {
    delete values.policy;
    const formData = new FormData()
    for (const key in values){
      formData.append(key, values[key])
    }
    axios.post(`/auth/signup${endpoint ? '/' + endpoint : ''}` , formData )
      .then((response) => {
        if (response.data) {
          this.setSession(response.data.token);
          sessionStorage.setItem('coursesToken', response.data.coursesToken)
          resolve(response.data.data);
        } else {
          reject(response.message);
        }
      })
      .catch((error) => {
        reject(error.message);
      });
  })

  loginInWithToken = () => new Promise((resolve, reject) => {
    axios.get('/user')
      .then((response) => {
        if (response.data.user) {
          if(window.location.pathname !== '/login' && !response.data.user.wpUserID){
            this.logout();
            window.location.href = '/login';
            return;
          }
          resolve(response.data.user);
        } else {
          if(response.data.code === 401){
            this.logout();
            window.location.href = '/login';
          }
          reject(response.data.error);
        }
      })
      .catch((error) => {
        if(error.code === 401){
            this.logout();
            window.location.href = '/login';
        }
        reject(error);
      });
  })

  logout = () => {
    this.setSession(null);
  }

  setSession = (accessToken) => {
    // debugger;
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      } else {
      localStorage.removeItem('accessToken');
      }
  }

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }
    try{
      var decoded = jwt_decode(accessToken);
      // const decoded = await jwtDecode(accessToken);
      const currentTime = Date.now() / 1000;
      return decoded.exp > currentTime;
    }
    catch(e){
      console.log(e);
    }

  };

  validateResetToken = (token) => new Promise((resolve, reject) => {
    axios.post('/user/validate-token', { resetToken: token })
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      })
      .catch((error) => {
        reject(error.error);
      });
  });

  resetPassword = (res) => new Promise((resolve, reject) => {
    axios.post('/user/reset-user-password', res)
      .then((response) => {
        if (response.data) {
          this.setSession(response.data.token);
          resolve(response.data.data);
        } else {
          reject(response.error);
        }
      })
      .catch((error) => {
        reject(error.error);
      });
  });

  SendInvalidTHEmailService=(res)=>new Promise((resolve, reject) => {
    axios.post('/user/sendthInvalidEmailReport', res)
      .then((response) => {
        if (response.data) {
          this.setSession(response.data.token);
          resolve(response.data.data);
        } else {
          reject(response.error);
        }
      })
      .catch((error) => {
        reject(error.error);
      });
  });

  isAuthenticated = () => !!this.getAccessToken()
}

const authService = new AuthService();

export default authService;
